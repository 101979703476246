import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ion-text-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_loading_skeleton = _resolveComponent("loading-skeleton")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('myStickers'),
        parentPath: "/home"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "secondary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                fill: "outline",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openStickerPreQuestionModal()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: $setup.add
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString($setup.t('sticker')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_loading_skeleton, { key: 0 }))
                : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userStickers, (sticker) => {
                        return (_openBlock(), _createBlock(_component_ion_col, {
                          "size-xs": "12",
                          "size-md": "6",
                          "size-lg": "4",
                          key: sticker.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card, {
                              "router-link": `/stickers/${sticker.id}`,
                              button: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_col, {
                                      "size-xs": "4",
                                      "size-md": "12",
                                      style: {"align-self":"center"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_1, [
                                          _createElementVNode("img", {
                                            style: {"max-height":"150px"},
                                            src: sticker.outputPhotoBase64 || sticker.outputPhoto
                                          }, null, 8, _hoisted_2)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_col, {
                                      "size-xs": "8",
                                      "size-md": "12"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_card_header, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_card_title, { style: {"font-size":"18px"} }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(sticker.name), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_card_subtitle, null, {
                                              default: _withCtx(() => [
                                                _createElementVNode("small", null, _toDisplayString($setup.t('lastUpdated')) + _toDisplayString($setup.formatDate(sticker.updatedAt)), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_card_content, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_buttons, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  slot: "icon-only",
                                                  "router-link": `/stickers/${sticker.id}`
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, { icon: $setup.createOutline }, null, 8, ["icon"])
                                                  ]),
                                                  _: 2
                                                }, 1032, ["router-link"]),
                                                _createVNode(_component_ion_button, {
                                                  slot: "icon-only",
                                                  onClick: _withModifiers(($event: any) => ($setup.deleteSticker(sticker.id)), ["prevent"])
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, { icon: $setup.trashOutline }, null, 8, ["icon"])
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["router-link"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}